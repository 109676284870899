.cardnlp {
    display: flex;
    flex-direction: column;
    background-color: white;
    border: solid 2px green;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
    height: 700px;
    font-family: Verdana;
}

.cardnlp h2 {
    margin-left: 20px;
}

.main {
    height: 90%;
    display: flex;
    margin-left: 20px;
    flex-direction: column;
}

.main input {
    height: 40px;
}

.formCache {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#addDb {
    height: 100%;
}

#addDb form {
    display: flex;
    flex-direction: column;
}

.add {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.input {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}

.badgeManagement_topBar {
    display: flex;
    height: 30px;
    align-items: center;
    justify-content: space-between;
}

.badgeManagement_topBar > h2 {
    flex: 0.16;
}

.filters {
    margin-top: 2px;
    display: flex;
}

.filters > * {
    flex: 0.2;
}


.filters_text {
    font-weight: normal;
}

.badge {
    display: flex;
    height: 30px;
    justify-content: space-between;
    border: solid 2px black;
    margin: 5px;
}

.badge > * {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    flex: 0.16;
    flex-direction: row;
}
.badge_range {
    display: flex;
}

.badge_range_input {
    width: 50%;
    margin-right: 2px;
}

.Mui_button {
    border: solid 2px rgb(90, 90, 90);
    border-radius: 5px;
    padding: 2px;
}

.badge > h2 {
    font-size: 0.675rem;
}

.badge > h3 {
    font-size: 0.875rem;
}

.badge_doc {
    font-size: 0.875rem;
    color: black;
}

.badge_img {
    height: 40px;
    max-width: 40px;
}

.documentsList {
    display: flex;
    height: 30px;
    align-items: center;
    justify-content: space-between;
}

.MuiMenuItem-root {
    max-width: 200px;
}

.addDocument {
    display: flex;
    height: 50px;
    align-items: left;
    justify-content: space-between;
}

.addDocument > * {
    flex: 0.25;
}

.documentsList_add > h5 {
    flex: 0.3;
    align-items: left;
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
}

.documentsList > h5 {
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
}

.documentsList > h6 {
    font-size: 0.1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.documentsList_cells {
    text-align: left;
    flex: 0.20;
}

.documentsList_cells_small {
    text-align: left;
    flex: 0.10;
}

.document_read {
    color: white;
    background-color: blue;
    border-radius: 10px;
    padding: 5px;
}

.document_activity {
    color: white;
    background-color: green;
    border-radius: 10px;
    padding: 5px;
}

.document_unselected {
    border-radius: 10px;
    padding: 5px;
    border: solid 2px rgb(90, 90, 90);
}

.document_removeButton {
    color: white;
    background-color: red;
    border-radius: 10px;
    padding: 5px;
}